<template>
  <v-container>
    <h3 class="text-center mt-2">Данные о компании</h3>
    <v-row class="mt-4">
      <v-col>
        <v-card class="mx-auto" style="max-width: 500px">
          <v-card-title>
            <p
              class="title mx-auto"
              :style="{
                position: 'relative',
                paddingLeft: '70px',
              }"
            >
              <v-img
                class="company-logo"
                :src="companyLogo"
                max-width="60"
                contain
              >
                <div class="company-logo_inner">
                  <div class="d-flex justify-space-between align-center h-100">
                    <v-file-input
                      class="filepicker"
                      accept="image/png, image/jpeg"
                      hide-input
                      prepend-icon="mdi-pencil"
                      @change="uploadLogo"
                    >
                    </v-file-input>

                    <v-btn icon small @click.stop="removeLogo">
                      <v-icon style="font-size: 16px">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div></v-img
              >
              {{ company.companyName }}
            </p>
          </v-card-title>
          <v-divider></v-divider>

          <v-list two-line>
            <v-list-item>
              <v-list-item-icon class="my-auto">
                <v-icon color="indigo"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ company.phone }}</v-list-item-title>
                <v-list-item-subtitle>Телефон</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon class="my-auto">
                <v-icon color="indigo"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ company.email }}</v-list-item-title>
                <v-list-item-subtitle>Эл. почта</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon class="my-auto">
                <v-icon color="indigo"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content :title="company.address">
                <v-list-item-title>{{ company.address }}</v-list-item-title>
                <v-list-item-subtitle>Адрес</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon class="my-auto">
                <v-icon color="indigo"> mdi-calendar-month </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  company.formattedDate
                }}</v-list-item-title>
                <v-list-item-subtitle>Дата регистрации</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, onBeforeMount, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const company = computed(() => store.getters.getCompany);

    const companyLogo = computed(() => store.getters.getCompanyLogo);

    onBeforeMount(async () => {
      await store.dispatch("fetchCompany");
    });

    const uploadLogo = file => {
      if (file.size <= 194560) {
        store.dispatch("uploadCompanyLogo", file);
      } else {
        root.$toast.error("Изображение не должно превышать 190КБ");
      }
    };

    const removeLogo = () => store.dispatch("removeCompanyLogo");

    onMounted(() => localStorage.setItem("lastTab", "Company"));

    return {
      company,
      companyLogo,
      uploadLogo,
      removeLogo,
    };
  },
};
</script>

<style lang="scss">
.company-logo {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 100%;
  &_inner {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 250, 0.9);
    display: none;
    padding: 0 2px;
  }
  &:hover {
    .company-logo {
      &_inner {
        display: block !important;
      }
    }
  }
  .filepicker {
    padding: 0;
    width: 28px;
    height: 28px;
    &:hover {
      .v-input__prepend-outer {
        display: block;
      }
    }
    .v-input__prepend-outer {
      margin: 0;
    }
    .v-icon {
      font-size: 16px;
      &::after {
        transform: scale(1.65);
      }
    }
  }
}
</style>
